import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { positionKeyText } from "_common/constants/statusType";
import { NotifyApi } from "states/api/notify";
import moment from "moment";

const ActivityPage = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { data, refetch } = useQuery(
    ["notification", "list"],
    () =>
      NotifyApi.getList({
        params: {
          page: 1,
          limit: 10
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="service-index">
      <HeaderTitle title="Trung tâm hoạt động" />
      <div className="service-box uni-page-wrapper">
        {
          data?.data?.map(item => (
          <Link to={`/activity/${item?.id}`} className="service-item" key={item?.id}>
            <div className="service__img" >
              <img src={item?.avatar_url} alt="" />
              </div>
            <div className="service-item__text">
              <p className="uni-h4 blue">{item?.title}</p>
              <p className="uni-h6">{item?.created_at && moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}</p>
            </div>
          </Link>
          ))
        }
      </div>
    </div>
  );
});

export default ActivityPage;
