import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const NotifyEndpoint = `${endpoint}/manager/common/notifications`;

export const NotifyApi = {
  getList: async ({ params }) =>
    await api
      .get(`${NotifyEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${NotifyEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
};
