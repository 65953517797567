import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

function HeaderLogin({ isBack = false, title }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="bg_header ">
      {isBack && (
        <span className="btn_back" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </span>
      )}
      <h1 className={classNames("bg_header_name")}>{title}</h1>
    </div>
  );
}

export default HeaderLogin;
