import React, { useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import { Outlet, useParams } from "react-router-dom";
import NotifyProvider from "_common/component/NotifyProvider";
import MobileFooter from "../Footer/MobileFooter";

const GameLayout = observer(() => {
  const { authStore } = useStores();
  const params = useParams();
  const gameId = params?.id;

  if (!authStore.logo) return <PageLoading />;
  if (!authStore.token) return <PageLoading />;
  if (!authStore.user?.id) return <PageLoading />;

  return (
    <div className="mobile-layout" style={{ paddingBottom: 0 }}>
      <div className="tab-layout">
        <NotifyProvider gameId={gameId}>
          <Outlet />
        </NotifyProvider>
      </div>
    </div>
  );
});

export default GameLayout;
