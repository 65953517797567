import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { formatTimeSecond } from "_common/utils/datetimeFormat";
import { get, random } from "lodash";
import { formatNumber } from "_common/utils/formatValue";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faCaretDown,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

import ModalGameHelp from "./ModalGameHelp";

const GameInfo = observer(({ gameInfo, dataGame }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { games, user },
  } = useStores();
  const [open, setOpen] = useState();

  const timeText = useMemo(() => {
    let time = Number(dataGame?.time);

    if (time < 0) {
      time = 0;
    }
    const textTime = formatTimeSecond(time);

    return textTime.split(":");
  }, [dataGame?.time]);

  return (
    <>
      <div className="game-lead">
        <div className="game-info">
          <div className="item">
            <div className="uni-h6">
              {i18next.t("balance_account")}:{" "}
              <b>{formatNumber(user?.balance)}</b>
            </div>
            <div className="uni-h6">
              {i18next.t("game_no")}: <b id="next">{gameInfo?.game_no}</b>
            </div>
            <div className="uni-h6">
              {i18next.t("expire_time")}:{" "}
              <div className="time">
                <b>{timeText?.[0]}</b>
                <span>:</span>
                <b>{timeText?.[1]}</b>
                {dataGame?.status !== "start" && (
                  <span>({i18next.t("waiting")})</span>
                )}
              </div>
            </div>
          </div>

          <div className="item right">
            <div className="uni-h6">
              <Link to="/histories/game">
                {i18next.t("detail_bet")}{" "}
                <FontAwesomeIcon icon={faAngleRight} />
              </Link>
            </div>
            <div className="uni-h6">
              {i18next.t("total_bet")}: <b>{formatNumber(user?.total_bet)}</b>
            </div>
            <div className="uni-h6">
              {i18next.t("profit_today")}:{" "}
              <b>{formatNumber(user?.total_profit_today)}</b>
            </div>
          </div>
        </div>
        <div className="game-prev">
          <p className="game-no">
            <span className="text">
              {i18next.t("game_no")} {gameInfo?.prev_game?.game_no}
            </span>
          </p>
          <div className="lotteryNumber" onClick={() => setOpen(!open)}>
            <span className="ball">
              {get(gameInfo, "prev_game.number1", 1)}
            </span>
            <span className="dau">+</span>
            <span className="ball">
              {get(gameInfo, "prev_game.number2", 1)}
            </span>
            <span className="dau">+</span>
            <span className="ball">
              {get(gameInfo, "prev_game.number3", 1)}
            </span>
            <span className="dau">=</span>
            <span className="ball red">
              {get(gameInfo, "prev_game.total_number", 3)}
            </span>
            <span className="text">(LONG,DOUBLE,LD)</span>
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
        </div>
      </div>
      {open && (
        <ModalGameHelp
          open={open}
          onClose={() => setOpen(!open)}
          gameInfo={gameInfo}
        />
      )}
    </>
  );
});

export default GameInfo;
