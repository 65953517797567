import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PaymentApi } from "states/api/payment";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import NoData from "_common/component/NoData";
import {
  paymentStatusColor,
  paymentStatusText,
} from "_common/constants/statusType";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function BalanceHistoryPage() {
  const navigate = useNavigate();

  const [params, setParams] = useState({});
  const { data, refetch } = useQuery(
    ["payment", "list_balance", params],
    () =>
      PaymentApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderBody = useMemo(() => {
    if (!data?.data?.length) return <NoData />;
    return data?.data?.map((item) => (
      <div
        className="list-item"
        key={item?.id}
        onClick={() => navigate(`/histories/balance/detail?id=${item?.id}`)}
      >
        <div className="top">
          <div
            className={classNames("status-text", {
              [paymentStatusColor[item?.status]]: true,
            })}
          >
            Nạp tiền {paymentStatusText[item?.status]}
          </div>
        </div>
        <div className="bottom">
          <div className="time">
            {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
          </div>
          <div className="value">+{formatNumber(item?.amount)}</div>
        </div>
      </div>
    ));
  }, [data]);

  return (
    <>
      <HeaderTitle title="Lich sử giao dịch" />
      <div className="container_main">
        <div className="recharge-record ">{renderBody}</div>
        <div className="adm-infinite-scroll">
          <span>--- Trống ---</span>
        </div>
      </div>
    </>
  );
}

export default BalanceHistoryPage;
