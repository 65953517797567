import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { positionKeyText } from "_common/constants/statusType";
import i18next from "i18next";

const ServicePage = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_fail_msg");
      NotificationManager.error(errorMessage);
    },
  });
  return (
    <div className="service-index">
      <div className="service-box uni-page-wrapper">
        <div className="service__icon_bg" />
        <div className="service-item">
          <div className="service__icon" />
          <div className="service-item__text">
            <div className="uni-h4 blue">{i18next.t("online_service")}</div>
            <div className="uni-h6">{i18next.t("tip_service")} </div>
          </div>
          <Link to="/support" className="service-item__btn">
            {i18next.t("click_service")}
          </Link>
        </div>
        <div className="service-item">
          <div className="service__icon" />
          <div className="service-item__text">
            <div className="uni-h4 blue">{i18next.t("online_service2")}</div>
            <div className="uni-h6">{i18next.t("tip_service")} </div>
          </div>
          <Link to="/support" className="service-item__btn">
            {i18next.t("click_service")}
          </Link>
        </div>
      </div>
    </div>
  );
});

export default ServicePage;
