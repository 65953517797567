import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useStores } from "_common/hooks";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import { observer } from "mobx-react-lite";
import PageLoading from "_common/component/PageLoading";
import { GameApi } from "states/api/game";
import Notice from "pages/Home/Notice";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

const GameHelpTip = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const {
    authStore: { games, general, logo },
  } = useStores();
  const { dataGame } = useNotify();

  return (
    <div className="game-pk3 pc-index">
      <HeaderTitle
        title="ETH"
        subTitle={<FontAwesomeIcon icon={faEllipsis} />}
      />
      <div className="help-text">
        <h5>{i18next.t("help_txt1")}</h5>
        <p>{i18next.t("help_txt2")}</p>
        <p>{i18next.t("help_txt3")}</p>
        <p>{i18next.t("help_txt4")}</p>
        <p>{i18next.t("help_txt5")}</p>
        <p>{i18next.t("help_txt6")}</p>
        <ul>
          <li>{i18next.t("help_txt7")}</li>
          <li>{i18next.t("help_txt8")}</li>
          <li>{i18next.t("help_txt9")}</li>
          <li>{i18next.t("help_txt10")}</li>
          <li>{i18next.t("help_txt11")}</li>
          <li>{i18next.t("help_txt12")}</li>
          <li>{i18next.t("help_txt13")}</li>
          <li>{i18next.t("help_txt14")}</li>
          <li>{i18next.t("help_txt15")}</li>
          <li>{i18next.t("help_txt16")}</li>
          <li>{i18next.t("help_txt17")}</li>
          <li>{i18next.t("help_txt18")}</li>
        </ul>
        <p>{i18next.t("help_txt19")}</p>
      </div>
    </div>
  );
});

export default GameHelpTip;
