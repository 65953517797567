import React from "react";
import { initReactI18next, I18nextProvider } from "react-i18next";
import i18next from "i18next";

import "react-notifications/lib/notifications.css";
import "swiper/swiper-bundle.css";

// @ts-ignore
import common_vi from "_common/i18n/vi/common.json";
// @ts-ignore
import common_cn from "_common/i18n/cn/common.json";
// @ts-ignore
import common_eng from "_common/i18n/eng/common.json";

import "./assets/scss/style.scss";
import Root from "./Root";

const i18n = i18next.use(initReactI18next);

i18n.init({
  react: {
    wait: true,
  },
  interpolation: { escapeValue: false },
  lng: "vi",
  fallbackLng: "vi",
  resources: {
    vi: {
      messages: common_vi,
    },
    cn: {
      messages: common_cn,
    },
    en: {
      messages: common_eng,
    },
  },
  keySeparator: ".",
  ns: ["messages"],
  defaultNS: "messages",
  fallbackNS: [],
});

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Root />
    </I18nextProvider>
  );
}

export default App;