import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { ProfileApi } from "states/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import classNames from "classnames";
import i18next from "i18next";

const LanguagePage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, language, setLanguage, languages },
  } = useStores();
  const [params] = useSearchParams();
  const type = params.get("type");

  return (
    <>
      <HeaderTitle title={i18next.t("language_select")} />
      <div className="language-page">
        <div className="lang-list">
          {languages?.map((item) => (
            <div
              onClick={() => setLanguage(item?.code)}
              className={classNames("item", {
                active: item?.code === language,
              })}
              key={item?.code}
            >
              <span>{item?.text}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

export default LanguagePage;
