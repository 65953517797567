import { get } from "lodash";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class AuthStore {
  token = "";

  user = null;

  device = "";

  banners = [];

  avatars = [];

  games = [];

  item_ball = [];

  item_number = [];

  bet_types = {};

  bet_list = {};

  order_random = [];

  languages = [];

  user_wallet = {};

  bank_default = {};

  game_wallet = {};

  general = {};

  game_rate = 1.96;

  logo = "";

  vip_image = "";

  vip1_image = "";

  notice = "";

  language = "vi";

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AuthStore",
      properties: ["token", "user", "device", "language"],
      storage: window.localStorage,
    });
  }

  get authenticated() {
    // TODO: This logic can be changed on demand
    return !!this.token;
  }

  clear = () => {
    this.token = "";
    this.user = null;
  };

  setInitData = (data) => {
    this.device = data?.device_code;
    this.game_rate = data?.game_rate;
    this.banners = data?.banners || [];
    this.languages = data?.languages || [];
    this.games = data?.games || [];
    this.item_ball = data?.item_ball || [];
    this.item_number = data?.item_number || [];
    this.bet_list = data?.bet_list || {};
    this.bet_types = data?.bet_types || {};
    this.order_random = data?.order_random || [];
    this.avatars = data?.avatars || [];
    this.vip_image = data?.vip_image;
    this.vip1_image = data?.vip1_image;
    this.notice = data?.notice;
    const general = data?.general || [];

    if (Array.isArray(general)) {
      const logoData = general?.find((obj) => obj?.name === "logo");
      if (logoData) {
        this.logo = logoData?.image_url;
      }
    }

    const newsValue = {};

    general.forEach((item, index) => {
      newsValue[item.name] = item.value;
      if (item.value === "0") {
        newsValue[item.name] = false;
      }
    });

    this.general = newsValue;
  };

  setLanguage = (value) => {
    this.language = value;
    window.location.reload();
  };

  updateToken = (token) => {
    this.token = token || this.token;
  };

  updateUser = (data) => {
    const tokens = get(data.user, "tokens", []) || [];
    this.user = data.user;
    this.bank_default = {
      bank_name: data.user?.bank_name,
      bank_number: data.user?.bank_number,
      bank_number_display: data.user?.bank_number_display,
      bank_owner: data.user?.bank_owner,
    };
    this.token = data?.token || this.token;
    this.user_wallet = tokens?.find((obj) => obj.symbol === "vnd");
    this.game_wallet = tokens?.find((obj) => obj.symbol === "game");
  };
}
