import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PaymentApi } from "states/api/payment";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import NoData from "_common/component/NoData";
import {
  paymentStatusColor,
  paymentStatusText,
} from "_common/constants/statusType";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import i18next from "i18next";

function DepositHistoryPage() {
  const navigate = useNavigate();

  const [params, setParams] = useState({});
  const { data, refetch } = useQuery(
    ["payment", "list_deposit", params],
    () =>
      PaymentApi.getList({
        params: {
          type: "deposit",
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderBody = useMemo(() => {
    if (!data?.data?.length) return <NoData />;
    return data?.data?.map((item) => (
      <div className="list-item" key={item?.id}>
        <div className="uni-text">+{formatNumber(item?.amount)}</div>
        <div className="uni-text">{paymentStatusText[item?.status]}</div>
        <div className="uni-text">
          {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      </div>
    ));
  }, [data]);

  return (
    <>
      <HeaderTitle title={i18next.t("deposit_history")} />
      <div className="container_main">
        <div className="list_header">
          <div className="uni-text">{i18next.t("amount_money")}</div>
          <div className="uni-text">{i18next.t("detail")}</div>
          <div className="uni-text">{i18next.t("time")}</div>
        </div>
        <div className="list_content">{renderBody}</div>
      </div>
    </>
  );
}

export default DepositHistoryPage;
