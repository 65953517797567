import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Link, useParams } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { positionKeyText } from "_common/constants/statusType";
import { NotifyApi } from "states/api/notify";
import moment from "moment";

const ActivityDetail = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();
  const params = useParams();
  const id = params?.id;

  const { data, refetch } = useQuery(
    ["notification", "detail", id],
    () =>
      NotifyApi.getDetail({
        id,
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <div className="service-index">
      <HeaderTitle title="Trung tâm hoạt động" />
      <div className="service-box uni-page-wrapper">
        <div className="content">
          <h2>{data?.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: data?.content }} />
          <img src={data?.avatar_url} alt="" />
        </div>
      </div>
    </div>
  );
});

export default ActivityDetail;
