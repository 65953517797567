import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faLock,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";

const SettingPage = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  return (
    <>
      <HeaderTitle title={i18next.t("setting_security")} />
      <div className="setting-container">
        <div className="menu-items">
          <Link to="/member/change-password" className="item ">
            <div className="left">
              <FontAwesomeIcon icon={faLock} />
              {i18next.t("login_password")}
            </div>
            <div className="right">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/change-wallet-password" className="item ">
            <div className="left">
              <FontAwesomeIcon icon={faWallet} />
              {i18next.t("wallet_password")}
            </div>
            <div className="right">
              {!user?.wallet_password && (
                <span>{i18next.t("not_setting")}</span>
              )}
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
});

export default SettingPage;
