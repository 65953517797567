import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { AuthApi } from "states/api";

import { Routers } from "routers";
import { useStores } from "_common/hooks";
import MobileToast from "_common/component/MobileToast";
import { useTranslation } from "react-i18next";

const basename = document.querySelector("base")?.getAttribute("href") ?? "/";

function Root() {
  const { t, i18n } = useTranslation();
  const {
    authStore: { device, language, token, setInitData, updateUser },
  } = useStores();

  useQuery(["get_device"], AuthApi.getDeviceCode, {
    staleTime: 300000,
    onSuccess: (data) => {
      setInitData(data);
    },
  });

  useQuery(["get_profile"], AuthApi.getProfile, {
    staleTime: 300000,
    enabled: !!token,
    onSuccess: (res) => {
      updateUser({ user: res?.data });
    },
  });

  useEffect(() => {
    try {
      i18n.changeLanguage(language);
    } catch (error) {
      //
    }
  }, [language]);

  return (
    <BrowserRouter basename={basename}>
      <Routers />
      <MobileToast />
    </BrowserRouter>
  );
}

export default Root;
