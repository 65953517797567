import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BankApi } from "states/api/bank";
import { optionBank } from "_common/constants/bank";
import { ProfileApi } from "states/api";
import i18next from "i18next";

const BankAddPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const schema = yup
    .object({
      bank_owner: yup.string().required(i18next.t("require_enter")),
      bank_number: yup.string().required(i18next.t("require_enter")),
      bank_name: yup.string().required(i18next.t("require_enter")),
      phone: yup.string().required(i18next.t("require_enter")),
    })
    .required();
  const [select_bank, setSelectBank] = useState();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        navigate(-1);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      // const bank = optionBank.find((obj) => obj.value === values?.bank_code);

      mutate({
        ...values,
        // bank_name: bank?.text,
      });
    }
  };

  useEffect(() => {
    if (!user) return;
    if (user?.bank_number) {
      setValue("bank_number", user?.bank_number_display);
      setValue("bank_name", user?.bank_name);
      setValue("bank_owner", user?.bank_owner);
      setValue("phone", user?.phone);
    }
  }, [user]);

  const isDisable = !!user?.bank_number;

  return (
    <>
      <HeaderTitle title={i18next.t("link_bank")} />

      <div className="payment-container bank-add-page">
        <form
          className="form-action form-horizontal"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className={classNames("form-item", {
              error: errors.bank_name,
            })}
          >
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_name"
                placeholder={i18next.t("enter_bank_name")}
                readOnly={isDisable}
                {...register("bank_name")}
              />
              <span className="error_t">{errors.bank_name?.message}</span>
            </div>
          </div>
          <div
            className={classNames("form-item", {
              error: errors.bank_owner,
            })}
          >
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_owner"
                placeholder={i18next.t("enter_bank_owner")}
                readOnly={isDisable}
                {...register("bank_owner")}
              />
              <span className="error_t">{errors.bank_owner?.message}</span>
            </div>
          </div>
          <div
            className={classNames("form-item", {
              error: errors.bank_number,
            })}
          >
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_number"
                placeholder={i18next.t("enter_bank_number")}
                readOnly={isDisable}
                {...register("bank_number")}
              />
              <span className="error_t">{errors.bank_number?.message}</span>
            </div>
          </div>
          <div
            className={classNames("form-item", {
              error: errors.phone,
            })}
          >
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="phone"
                placeholder={i18next.t("enter_phone")}
                readOnly={isDisable}
                {...register("phone")}
              />
              <span className="error_t">{errors.phone?.message}</span>
            </div>
          </div>
          {!isDisable && (
            <button
              type="submit"
              className={classNames("btn btn-sub", {
                active: true,
              })}
            >
              {i18next.t("confirm")}
            </button>
          )}
        </form>
      </div>
    </>
  );
});

export default BankAddPage;
