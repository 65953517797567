import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import HeaderLogin from "_common/component/Layout/Header/HeaderLogin";

import RegisterForm from "./RegisterForm";

function RegisterPage() {
  const navigate = useNavigate();
  const {
    authStore: { logo },
  } = useStores();

  return (
    <div className="remix-auth-page">
      <HeaderLogin title={i18next.t("register")} isBack />
      <div className="bg-wrapper">
        <div className="auth-container">
          <RegisterForm />
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
