import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import classNames from "classnames";
import InputPassword from "_common/component/InputPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPhone, faTimes } from "@fortawesome/free-solid-svg-icons";
import enImg from "assets/img/icon/en.png";
import i18next from "i18next";

export default function RegisterForm() {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const schema = yup
    .object({
      username: yup.string().required(i18next.t("require_enter")),
      password: yup.string().required(i18next.t("require_enter")),
    })
    .required();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data);
        authStore.updateUser(res?.data);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-form-user" onSubmit={handleSubmit(handleRegister)}>
      <div>
        <ul>
          <li
            className={classNames("marginBottom", {
              error: errors.username,
            })}
          >
            <div className="form_In">
              <div className="field-group">
                <FontAwesomeIcon icon={faPhone} />
                <input
                  className="field_control"
                  id="username"
                  type="text"
                  placeholder={i18next.t("username")}
                  {...register("username")}
                />
              </div>
              <span className="error_t">{errors.username?.message}</span>
            </div>
          </li>
          <li
            className={classNames("marginBottom", {
              error: errors.password,
            })}
          >
            <div className="form_In">
              <div className="field-group">
                <FontAwesomeIcon icon={faLock} />
                <InputPassword
                  className="field_control"
                  id="password"
                  placeholder={i18next.t("password")}
                  {...register("password")}
                />
              </div>
              <span className="error_t">{errors.password?.message}</span>
            </div>
          </li>
        </ul>
        <div className="form_button">
          <button type="submit" className="btn btn-submit">
            {i18next.t("register")}
          </button>
        </div>
        <p className="signin-other-title">
          <Link to="/auth/login">{i18next.t("login")}</Link>
        </p>
        <div className="login-footer">
          <Link to="/language" className="lang">
            <img src={enImg} alt="" />
            <span>{i18next.t("select_language")}</span>
          </Link>
          <Link className="btn-support" to="/support">
            support{" "}
          </Link>
        </div>
      </div>
    </form>
  );
}
