import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { formatNumber } from "_common/utils/formatValue";
import { Link } from "react-router-dom";
import i18next from "i18next";

import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faGolfBall } from "@fortawesome/free-solid-svg-icons";

import ListBanner from "./ListBanner";
import Notice from "./Notice";
import Profit from "./Profit";

const Home = observer(() => {
  const {
    authStore: { user_wallet },
  } = useStores();

  return (
    <div className="page-container">
      <div className="home-page">
        <ListBanner />
        <Notice />
        <div className="grid">
          <div className="grid__type_l">
            <Link to="/room" className="grid__type_l_img">
              <h2>USDT</h2>
              <h3>Trading Center</h3>
            </Link>
          </div>
          <div className="grid__type_r" style={{ width: "100%" }}>
            <Link to="/language" className="grid__type_btn">
              <FontAwesomeIcon icon={faGolfBall} />
              <span className="grid__title_fr">{i18next.t("language")}</span>
            </Link>
            <Link to="/activity" className="grid__type_btn">
              <FontAwesomeIcon icon={faCoins} />
              <span className="grid__title_fr">
                {i18next.t("activity_center")}
              </span>
            </Link>
          </div>
        </div>
        <Profit />
      </div>
    </div>
  );
});

export default Home;
