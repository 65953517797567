import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PaymentApi } from "states/api/payment";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import NoData from "_common/component/NoData";
import {
  gameBetItemType,
  gameLevelText,
  gameStatusText,
  gameTypeText,
} from "_common/constants/statusType";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { GameApi } from "states/api/game";
import classNames from "classnames";
import i18next from "i18next";

function GameHistoryPage() {
  const navigate = useNavigate();

  const [params, setParams] = useState({});
  const { data, refetch } = useQuery(
    ["game", "list", params],
    () =>
      GameApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderBody = useMemo(() => {
    if (!data?.data?.length) return <NoData />;
    return data?.data?.map((item) => (
      <div className="list_header item" key={item?.id}>
        <div className="uni-text">{item?.game?.game_no}</div>
        <div className="uni-text">
          {i18next.t(gameStatusText[item?.status])}
        </div>
        <div className="uni-text">{formatNumber(item?.amount)}</div>
        <div className="uni-text">{formatNumber(item?.amount_payback)}</div>
      </div>
    ));
  }, [data]);

  return (
    <>
      <HeaderTitle title={i18next.t("detail_bet")} />
      <div className="container_main history_page">
        <div className="list_header">
          <div className="uni-text">{i18next.t("number")}</div>
          <div className="uni-text">{i18next.t("result")}</div>
          <div className="uni-text">{i18next.t("my_bet")}</div>
          <div className="uni-text">{i18next.t("profit")}</div>
        </div>
        <div className="list_content ">{renderBody}</div>
      </div>
    </>
  );
}

export default GameHistoryPage;
