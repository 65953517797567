import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useStores } from "_common/hooks";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import { observer } from "mobx-react-lite";
import PageLoading from "_common/component/PageLoading";
import { GameApi } from "states/api/game";
import Notice from "pages/Home/Notice";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

import GameInfo from "./GameInfo";
import GameFooter from "./GameFooter";
import GameHis from "./GameHis";

const PK3Page = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const {
    authStore: { games, general, logo },
  } = useStores();
  const { dataGame } = useNotify();

  const gameId = params?.id;
  const [selectItem, setSelectItem] = useState([]);

  const { data: gameInfo, refetch } = useQuery(
    ["game", "detail", gameId],
    () =>
      GameApi.getGame({
        params: {
          page: 1,
          limit: 10,
          code: gameId,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const timeEnd = useMemo(() => dataGame?.time <= 5, [dataGame]);

  const timeStart = useMemo(() => dataGame?.time > 0, [dataGame]);

  useEffect(() => {
    refetch();
  }, []);

  if (!gameInfo?.id) return <PageLoading />;

  return (
    <div className="game-pk3 pc-index">
      <HeaderTitle
        title={`${i18next.t(gameInfo?.room?.name)} (${gameInfo?.room?.type})`}
        subTitle={
          <FontAwesomeIcon
            icon={faEllipsis}
            onClick={() => navigate("/game/help")}
          />
        }
      />
      <div className="board-game">
        <GameInfo
          gameInfo={gameInfo}
          dataGame={dataGame}
          timeEnd={timeEnd}
          timeStart={timeStart}
        />
        <GameHis gameInfo={gameInfo} dataGame={dataGame} />
        <GameFooter
          timeEnd={timeEnd}
          timeStart={timeStart}
          gameInfo={gameInfo}
          dataGame={dataGame}
          setSelectItem={setSelectItem}
          selectItem={selectItem}
        />
      </div>
    </div>
  );
});

export default PK3Page;
