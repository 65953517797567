import { createContext } from "react";
import { UserStore } from "./userStore";
import { AuthStore } from "./authStore";
import { ToastStore } from "./toastStore";

export class RootStore {
  constructor() {
    this.authStore = new AuthStore();
    this.userStore = new UserStore();
    this.toastStore = new ToastStore();
  }
}
export const rootStore = new RootStore();
export const rootStoreContext = createContext(rootStore);
